.navbar {
  @apply bg-gray-800 text-electriclime-500;

  &__container {
    @apply max-w-6xl mx-auto px-2 border-b-2 border-electriclime-500;
  }

  &__logo {
    @apply flex space-x-1 items-center w-24 my-2 grow md:grow-0;

    img {
      @apply h-10 mx-2;
    }
  }
  &__primary {
    @apply hidden md:flex items-center space-x-3;

    a {
      @apply px-3 py-2;
    }
  }
  &__primary_button {
    @apply left-0 py-4 bg-gray-800 text-electriclime-500 z-50;
  }

  &__language_dropdown{
    @apply absolute -left-20 py-2 mt-0 bg-gray-800 text-electriclime-500 w-32 z-50 border-t-2 border-electriclime-500;

    a {
      @apply block px-4 py-2 text-sm hover:bg-electriclime-500 hover:text-gray-800;
    }
  }

  &__primary_dropdown{
    @apply absolute -left-4 py-2 mt-0 bg-gray-800 text-electriclime-500 w-52 z-50 border-t-2 border-electriclime-500;

    a {
      @apply block px-4 py-2 text-sm hover:bg-electriclime-500 hover:text-gray-800;
    }
  }

  &__mobile_button {
    @apply md:hidden flex items-center;
  }

  &__mobile {
    @apply relative overflow-hidden transition-all duration-700;

    a {
      @apply block py-1 px-2 hover:bg-electriclime-400;
    }

  }
  &__mobile_dropdown_button {
    @apply block py-1 px-2 hover:bg-electriclime-200 text-left w-full;
  }
  &__mobile_dropdown {
    @apply relative overflow-hidden transition-all duration-700;

    a {
      @apply block px-4 py-2 text-sm hover:bg-electriclime-400 hover:text-gray-800;
    }
  }
}