.loc_desc {
  @apply md:p-4;

  &__wrap {
    @apply text-gray-900 border-gray-900 bg-gray-100;
  }

  &__main {
    @apply p-4 md:p-0 text-lg flex flex-col
  }

  &__title {
    @apply text-2xl font-bold ml-4 my-4 hidden md:block;
  }

  &__body {
    @apply md:m-4;

    h3 {
      @apply font-bold mb-2;
    }

    p {
      @apply mb-2;
    }
  }
}
