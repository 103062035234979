.boat_card {
  @apply border border-gray-300 duration-500 ease-out transform hover:-translate-y-1 hover:shadow-xl;
  &__image {
    @apply h-56 overflow-hidden;
  }

  &__title {
    @apply bg-gray-800 text-electriclime-500;
  }

  &__name {
    @apply flex-grow text-xl capitalize py-2;
  }

  &__make {
    @apply w-24 py-2 text-xs object-right;
  }

  &__underline {
    @apply h-2 border-t-2 border-electriclime-500;
  }

  &__icon_bar {
    @apply flex justify-between border-b border-gray-300 p-2;
  }

  &__icon {
    @apply items-center py-2;
    svg {
      @apply h-6 w-6 mx-auto;
    }
  }

  &__icon_text {
    @apply text-xs text-center;
  }
}