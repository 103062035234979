/* If you need to add @import statements, do so up here */

@import 'addsite_overrides.css';
@import 'alpine_x_cloak.css';
@import 'boat_card.scss';
@import 'cdnfonts_neo_sans_tr.css';
@import 'cta_book_now.scss';
@import 'cta_try_out_week.scss';
@import 'lite-youtube-embed/src/lite-yt-embed.css';
@import 'location.scss';
@import 'navbar.scss';
@import 'vragen.scss';
@import 'route.scss';
@import 'swiper.css';
@import 'top_banner.scss';

@import "jit-refresh.css";  /*triggers frontend rebuilds*/

/* Set up Tailwind imports */
@tailwind base;
@tailwind components;
@tailwind utilities;

