/* purgecss start ignore */
.addsitewidget .maanden {
  @apply h-12 mb-2 md:mb-4;
}

.addsitewidget table.box-table-b.prsn td:first-child {
  @apply pt-0;
}
.addsitewidget >h2 {
  display: none;
}

.addsitewidget #addkalenders br {
  display: none;
}

.addsitewidget > .kalender {
  min-height: 330px;
}

.addsitewidget .addlink.prev,
.addsitewidget .addlink.next,
.addsitewidget .addlink.boekenlink {
  @apply rounded-none shadow-md;
}

.addsitewidget .addlink.prev,
.addsitewidget .addlink.next {
  @apply text-sm text-electriclime-500 bg-gray-800 border-b-2 border-electriclime-500;
}

.addsitewidget .addlink.boekenlink {
  @apply bg-gray-800 text-sm text-electriclime-500 border-b-2 border-electriclime-500 px-4;
}

.addsitewidget table.cal_box .headerrow {
  @apply bg-gray-800 text-lg text-electriclime-500;
}

.addsitewidget .addekalenders .kalender {
  @apply p-0;
}

.addsitewidget #addperiodes {
  @apply text-lg;
}

.addsitewidget #boeken_wrapper {
  font-family: 'Neo Sans TR', ui-sans-serif, system-ui;
  @apply text-lg px-0;
  @apply pt-0;
}

.addsitewidget .oranje {
  @apply text-orange-500;
}

.addsitewidget .groen {
  @apply text-electriclime-500;
}

.addsitewidget .kalender .bookable.free.free_today.avail_100 {
  @apply bg-electriclime-600 !important;
}

.addsitewidget .kalender .bookable.free.free_today.avail_75_100,
.addsitewidget .kalender .bookable.free.free_today.avail_50_75,
.addsitewidget .kalender .bookable.free.free_today.avail_25_50 {
  @apply bg-electriclime-500 !important;
}

.addsitewidget .kalender .bookable.occupied.occupied_today {
  @apply bg-red-500 !important;
}


.addsitewidget .stappen {
  @apply bg-gray-200 shadow-none rounded-none;
}

.addsitewidget .stappen ul li {
  @apply bg-gray-200;
}

.addsitewidget .box-table-b {
  @apply text-lg;
}

.addsitewidget input,
.addsitewidget select,
.addsitewidget .box-table-b input,
.addsitewidget .box-table-b input.addformInputSmall,
.addsitewidget .box-table-b select,
.addsitewidget .box-table-b textarea.addformArea {
  @apply rounded-none !important;

  @apply border border-gray-400;
  @apply w-full md:w-1/2;
}

.addsitewidget .box-table-b input[type=checkbox] {
  @apply w-auto;
}

.addsitewidget input.button_verder, input.button_terug {
  @apply w-full md:w-48;
}

.addsitewidget .box-table-b tr td:first-child {
  min-width: 3rem;
}
/* purgecss end ignore */


.addsitewidget #boeken_wrapper label {
  @apply font-medium ;
}

#boeken {
  min-height: 700px;
}

.addsitewidget label a {
  @apply  text-electriclime-500;
}

