.itenerary {
  @apply text-gray-900 border-gray-900 bg-gray-100 p-4 text-lg flex flex-col mt-2;

  h2 {
    @apply text-2xl font-bold ml-4 mb-4;
  }

  &__step {
    @apply mb-4 ;

    h3 {
      @apply font-semibold mb-2;
    }

    &__chargeloc {
      @apply mt-2;

      a {
        @apply text-electriclime-500 hover:underline;
      }
    }
    &__desc {
       p {
        @apply mb-2;
      }
    }
    &__data {
      @apply italic;
    }
  }
}

.route_data {
  @apply italic my-2
}

.route_description {
  @apply text-lg my-2;

  h2 {
    @apply font-bold mt-2;
  }

  ul {
    @apply list-disc list-inside text-base;
  }
}
