.try_out_week {
  @apply py-8 px-4 mx-auto sm:py-16 lg:px-6 bg-green-50 my-12 rounded-lg;
  &__container {
    @apply mx-2 sm:mx-16 w-auto;
  }
  &__title {
    @apply mb-4 text-3xl tracking-tight font-bold leading-tight text-electriclime-700;
  }
  &__subtitle {
    @apply mb-6 text-electriclime-800 md:text-lg;
  }
  &__content {
    p {
      @apply mb-2;
    }
    @apply mb-6 text-electriclime-800 md:text-lg;
  }
  &__link {
    @apply w-full md:w-48 h-20 mb-4 inline-flex items-center justify-center px-5 py-3 mr-3 text-base font-medium text-center
    text-white rounded-lg bg-electriclime-600 hover:bg-electriclime-500 focus:ring-4 focus:ring-electriclime-500 ;
  }
  &__sold_out_link {
    @apply w-full md:w-48 h-20 mb-4 inline-flex items-center justify-center px-5 py-3 mr-3 text-base font-medium text-center
    text-white rounded-lg bg-gray-600 hover:bg-gray-500 focus:ring-4 focus:ring-gray-500;
  }
}

