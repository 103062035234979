.top_banner {
  @apply flex grid grid-cols-1 my-2;

  &__cover_image {
    @apply w-full h-96 bg-cover bg-center p-0 md:p-6 md:py-16;
  }

  &__text_box {
    @apply flex flex-col mx-auto my-auto w-full px-2 h-1/3 md:h-1/2 bg-gray-800 rounded-b border-t-2 border-electriclime-500 md:w-3/5 mt-64 md:mt-auto opacity-90 hover:opacity-40;

    h1 {
      @apply text-electriclime-500 text-xl md:text-2xl text-center w-full block mt-0 md:mt-2;
    }
    h2 {
      @apply text-white text-center w-full block;
    }
    h3 {
      @apply text-white text-xs md:text-sm text-center w-full block;
    }
  }
}