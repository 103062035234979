@font-face {
    font-family: 'Neo Sans TR';
    font-style: normal;
    font-weight: 400;
    src: local('Neo Sans TR'), url('https://fonts.cdnfonts.com/s/13315/NeoSans TR.woff') format('woff');
    font-display: swap;
}
@font-face {
    font-family: 'Neo Sans TR';
    font-style: italic;
    font-weight: 300;
    src: local('Neo Sans TR'), url('https://fonts.cdnfonts.com/s/13315/NeoSans Light Italic TR.woff') format('woff');
    font-display: swap;
}
@font-face {
    font-family: 'Neo Sans TR';
    font-style: normal;
    font-weight: 500;
    src: local('Neo Sans TR'), url('https://fonts.cdnfonts.com/s/13315/NeoSans Medium TR.woff') format('woff');
    font-display: swap;
}
@font-face {
    font-family: 'Neo Sans TR';
    font-style: normal;
    font-weight: 900;
    src: local('Neo Sans TR'), url('https://fonts.cdnfonts.com/s/13315/NeoSans Black  TR.woff') format('woff');
    font-display: swap;
}
@font-face {
    font-family: 'Neo Sans TR';
    font-style: italic;
    font-weight: 900;
    src: local('Neo Sans TR'), url('https://fonts.cdnfonts.com/s/13315/NeoSans UltraItalic TR.woff') format('woff');
    font-display: swap;
}
@font-face {
    font-family: 'Neo Sans  TR';
    font-style: italic;
    font-weight: 400;
    src: local('Neo Sans  TR'), url('https://fonts.cdnfonts.com/s/13315/NeoSans Italic TR.woff') format('woff');
    font-display: swap;
}
@font-face {
    font-family: 'Neo Sans';
    font-style: normal;
    font-weight: 300;
    src: local('Neo Sans'), url('https://fonts.cdnfonts.com/s/13315/neo-sans-light.woff') format('woff');
    font-display: swap;
}
@font-face {
    font-family: 'Neo Sans  TR';
    font-style: italic;
    font-weight: 500;
    src: local('Neo Sans  TR'), url('https://fonts.cdnfonts.com/s/13315/NeoSansMedium Italic TR.woff') format('woff');
    font-display: swap;
}
@font-face {
    font-family: 'Neo Sans  TR';
    font-style: normal;
    font-weight: 700;
    src: local('Neo Sans  TR'), url('https://fonts.cdnfonts.com/s/13315/NeoSans Bold TR.woff') format('woff');
    font-display: swap;
}
@font-face {
    font-family: 'Neo Sans  TR';
    font-style: normal;
    font-weight: 900;
    src: local('Neo Sans  TR'), url('https://fonts.cdnfonts.com/s/13315/NeoSans UltraTR.woff') format('woff');
    font-display: swap;
}
@font-face {
    font-family: 'Neo Sans  TR';
    font-style: italic;
    font-weight: 900;
    src: local('Neo Sans  TR'), url('https://fonts.cdnfonts.com/s/13315/NeoSans Black Italic TR.woff') format('woff');
    font-display: swap;
}

