.vragenlijst {
  @apply p-2 bg-gray-200 mb-4 md:h-screen md:sticky top-0;
  h2 {
    @apply text-xl font-bold my-2 mb-6;
    small {
       @apply text-sm text-electriclime-500 italic;
    }
  }
  li {
    @apply text-lg  mb-2;
  }
}
.vragen {
  @apply grid gap-4 grid-cols-1 p-0 md:p-4;
  .vraag {
    h2 {
      @apply text-xl font-bold mx-4;
      small {
        @apply text-sm text-electriclime-500 italic;
      }
    }
  }
  .antwoord {
    @apply mx-4 my-2;
    p {
      @apply mb-2;
    }
  }
  a {
    @apply text-electriclime-500 hover:underline;
  }
}